// Customizable Area Start
import React from "react";
import HomeScreenAccountApprovedController, {
    Props,
} from "./HomeScreenAccountApprovedController";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/styles";
import { analyticsImage, createClientImage, previousQuotesImage } from "./assets";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';


export default class HomeScreenAccountApproved extends HomeScreenAccountApprovedController {
    constructor(props: Props) {
        super(props);
    }


    render() {
        return (
            <>

                <Box style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <MainBox  >
                        <Box>
                            <Typography
                                style={{
                                    fontFamily: "Quicksand",
                                    fontSize: "32px",
                                    fontWeight: 700,
                                    lineHeight: "40px",
                                    textAlign: "left",
                                }}>
                                Welcome, Peter
                            </Typography>
                        </Box>

                        <Box>
                            <Typography
                                style={{
                                    fontFamily: "Quicksand",
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    lineHeight: "17.5px",
                                    textAlign: "left",
                                }}>
                                Welcome! Here, you can create new clients, view past quotes, and access insightful analytics—all in one place.
                            </Typography>
                        </Box>
                        <Box
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center", flexDirection: "column",
                                gap: "8px",
                            }}>
                            <Box style={{ borderRadius: "16px", overflow: "hidden", borderLeft: " 6px solid #C7161D", position: "relative" }}>
                                <img src={createClientImage.default} style={{ width: "100%", filter: "grayscale(100%)" }} />
                                <Typography style={webstyle.cardTypo}>Create Client</Typography>
                                <KeyboardArrowRightIcon style={webstyle.cardArrow}></KeyboardArrowRightIcon>
                            </Box>
                            <Box style={{ borderRadius: "16px", overflow: "hidden", borderLeft: " 6px solid #455992", position: "relative" }}>
                                <img src={previousQuotesImage.default} style={{ width: "100%", filter: "grayscale(100%)" }} />
                                <Typography style={webstyle.cardTypo}>Previos Quotes</Typography>
                                <KeyboardArrowRightIcon style={webstyle.cardArrow}></KeyboardArrowRightIcon>
                            </Box>
                            <Box style={{ borderRadius: "16px", overflow: "hidden", borderLeft: " 6px solid #C7161D", position: "relative" }}>
                                <img src={analyticsImage.default} style={{ width: "100%", filter: "grayscale(100%)" }} />
                                <Typography style={webstyle.cardTypo}>Analytics</Typography>
                                <KeyboardArrowRightIcon style={webstyle.cardArrow}></KeyboardArrowRightIcon>
                            </Box>
                        </Box>
                    </MainBox>
                </Box >

            </>

        );
    }
}

const MainBox = styled(Box)({
    display: "flex",
    maxWidth: "608px",
    flexDirection: "column",
    gap: "32px",
    "@media (max-width:960px)": {
        width: "90%"
    },
    "@media (min-width:720px)": {
    },
});

const webstyle = {
    cardTypo: {
        position: "absolute" as "absolute",
        color: "white",
        top: "35%",
        left: "30px",
        fontFamily: "Quicksand !important",
        fontSize: "28px",
        fontWeight: 700,
        lineHeight: "35px !important",
        textAlign: "left" as "left",
    },
    cardArrow: {
        position: "absolute" as "absolute",
        color: "white",
        top: "40%",
        left: "90%",
        fontFamily: "Quicksand !important",
        fontSize: "32px",
    },

};

// Customizable Area End
