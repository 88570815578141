import React from "react";

// Customizable Area Start
import { StyleSheet } from "react-native";

import {
  Container,
  InputAdornment,
  Link,
  Box,
  createTheme,
  CssBaseline,
  ThemeProvider,
  Typography,
  TextField,
  Button,
  IconButton,
  styled
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import { imgTigerLily, imgBciBackdrop } from "./assets";

const theme = createTheme({
  typography: {
    fontFamily: "Quicksand",
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            backgroundColor: "#E7E5E4",
            color: "#171717",
            borderRadius: 10,
            fontSize: 16,
            fontWeight: 500,
            height: "56px",
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
          },
          "& input[type=number]": {
            "-moz-appearance": "textfield",
          },
          "& input[type=number]::-webkit-outer-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
          },
          "& input[type=number]::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
          },
          "& input:-webkit-autofill": {
            transition:
              "background-color 0s 600000s, color 0s 600000s !important",
          },
          "& input": {
            paddingBlock: 16,
            paddingInline: 18,
            color: "#171717",
          },
          "& .MuiInputBase-root.MuiOutlinedInput-root:hover:not(.Mui-focused)":
          {
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
              borderWidth: 2,
            },
          },
        },
      },
    },
  },
});

const ImageContainer = styled('div')({
  position: 'relative',
  width: '50%',
  height: '100%',
  maxWidth: '720px',
})

const BackdropImage = styled('img')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  zIndex: 1,
  borderRadius: '36px',
})

const MainImage = styled('img')({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  height: '100%',
  borderRadius: '36px',
})
// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container sx={{ width: '100vw', height: '100vh', display: 'flex' }}>
          <ImageContainer>
            <BackdropImage src={imgBciBackdrop} alt="BCI Logo" />
            <MainImage src={imgTigerLily} alt="Tigers Lily" />
          </ImageContainer>

          <Box
            sx={{
              flex: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '2rem',
              boxShadow: 'none',
              borderTopRightRadius: '36px',
              borderBottomRightRadius: '36px',
            }}
          >
            <form>
              <Typography variant="h4" gutterBottom sx={{ margin:'16px 0 32px', fontWeight: '700', color: '#171717', fontSize: '32px', lineHeight: '40px'}}>
                Sign In
              </Typography>
              {this.state.error && (
                <Typography color="error" sx={{ mb: 2 }}>
                  ⚠ Incorrect email or password
                </Typography>
              )}

              <Typography variant="h6" sx={{ margin:'8px 0 0', fontWeight: '700', color: '#78716C', fontSize: '16px', lineHeight: '24px' }}>
                Email
              </Typography>
              <TextField
                fullWidth
                label="Email"
                type="email"
                name="email"
                value={this.state.email}
                margin="normal"
                required
                data-test-id="txtInputEmail"
              />
              <Typography variant="h6" sx={{ margin:'8px 0 0', fontWeight: '700', color: '#78716C', fontSize: '16px', lineHeight: '24px' }}>
                Password
              </Typography>
              <TextField
                fullWidth
                label="Password"
                type={this.state.showPassword ? 'text' : 'password'}
                name="password"
                value={this.state.password}
                margin="normal"
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={this.togglePasswordVisibility} edge="end">
                        {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                data-test-id="txtInputPassword"
              />
              <Box sx={{ textAlign: 'right', mt: 1 }}>
                <Link data-test-id="btnForgotPassword" href="#" variant="body2" sx={{ fontWeight: 'bold', textDecoration: 'none', color: '#171717' }}>
                  Forgot your password?
                </Link>
              </Box>
              <Button
                fullWidth
                type="submit"
                variant="contained"
                sx={{
                  mt: 3, backgroundColor: '#C7161D', '&:hover': {
                    backgroundColor: '#171717',
                  },
                  textTransform: 'capitalize'
                }}
              >
                Log In
              </Button>
              <Typography variant="body2" sx={{ fontFamily: 'Cerebri Sans', fontSize: '12px', fontWeight: '700', lineHeight: '15px', textAlign: 'center', mt: 2, color: '#171717' }}>
                Don't have an account yet?{' '}
                <Link href="/register" variant="body2" sx={{ fontFamily: 'Cerebri Sans', fontSize: '12px', fontWeight: '700', lineHeight: '15px', color: '#C7161D', textDecorationColor: '#C7161D', cursor:'pointer' }}>
                  Create One
                </Link>
              </Typography>
            </form>
          </Box>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }

}

const styles = StyleSheet.create({
  main: {
    display: "flex",
    alignItems: "center"
  },
  body: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
    width: "50%",
  },
  textFont: {
    fontFamily: "Helvetica"
  },
  formWrapper: {
    display: "flex",
    width: "100%",
    paddingTop: "20px" 
  },
  inputWrapper: {
    flex: 1,
    padding: "20px"
  },
  removeBackground: {
    backgroundColor: "transparent" 
  },
  checkBoxText: {
    fontSize: 18,
    color: "#6200EE",
    fontWeight: "bold",
  },
  buttonsWrapper: {
    flex: 1,
    padding: "10px",
  },
  OrWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
  }
});
